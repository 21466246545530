@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,400;1,500;1,800;1,900&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

.cto {
  background: linear-gradient(to right, rgb(227, 87, 0), rgb(188, 0, 0));

  background-clip: text;
  color: transparent;
  text-align: center;
  font-size: 1.8em;
  /* padding: 1em; */
}

@media only screen and (min-width: 600px) {
  .header {
    display: flex;
    padding: 0.5em 2em 0.5em 2em;
    gap: 2em;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(216, 216, 216);
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding: 0.5em;
  }

  .clientes {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    align-items: center;
  }
}

@media only screen and (max-width: 599px) {
  .header {
    display: flex;
    padding: 0.5em 2em 2em 2em;
    gap: 2em;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(216, 216, 216);
    flex-direction: column;
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding: 0.5em;
  }

  .clientes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.App-link {
  color: #61dafb;
}

/* From uiverse.io by @adamgiebl */
.button-old {
  color: #090909;
  padding: 0.7em 1.7em;
  font-size: 18px;
  border-radius: 0.5em;
  background: #e8e8e8;
  border: 1px solid #e8e8e8;
  transition: all 0.3s;
  box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
}

.button-old:hover {
  border: 1px solid white;
  cursor: pointer;
}

.button-old:active {
  box-shadow: 4px 4px 12px #c5c5c5, -4px -4px 12px #ffffff;
}

.text {
  align-items: center;
}

.footer {
  display: flex;
  padding: 2em 2em 2em 2em;
  gap: 2em;
  align-items: center;
  justify-content: space-between;
  background-color: #282c34;
  flex-direction: column;
  color: rgb(216, 216, 216);
}

a {
  color: #020202;
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

.MuiPaper-root:hover {
  transform: scale(1.1);
}

.MuiTypography-root {
  color: #020202 !important;
}

/* CSS */
.btn-header {
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: transparent 0 0 0 3px, rgba(18, 18, 18, 0.1) 0 6px 20px;
  box-sizing: border-box;
  color: #121212;
  cursor: pointer;
  display: inline-flex;
  font-family: Inter, sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  justify-content: center;
  line-height: 1;
  margin: 0;
  outline: none;
  padding: 1rem 1.2rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
  white-space: nowrap;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.btn-header:hover {
  box-shadow: #121212 0 0 0 3px, transparent 0 0 0 0;
}

.el3d {
  width: 40vw;
}

.logo {
  width: 50vw;
}
.no-link {
  text-decoration: none;
  color: rgb(216, 216, 216);
}

.no-link :hover {
  text-decoration: none;
  color: rgb(255, 255, 255) !important;
}

a:hover {
  color: rgb(255, 255, 255) !important;
}
