@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,400;1,500;1,800;1,900&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

h1 {
  color: #2c2c2c;
}

h2 {
  color: #b1b1b1;
}

.h2 {
  color: #b1b1b1;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
